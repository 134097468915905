<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Custom Fields <span>Add New Field</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form @submit="handleSubmitForm" v-slot="{ errors }" ref="add-custom-field-form">
                    <div class="setting_wpr">
                        <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Field Name</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                        <Field autocomplete="off" type="text" v-model="form.name" name="name" placeholder="Enter field name here" rules="required" />
                                    </div>
                                    <ErrorMessage name="name" class="text-danger" />
                                </div>
                        </div>
                        <label for="active_field" class="switch_option capsule_btn">
                            <h5>Active</h5>
                            <input type="checkbox" v-model="form.is_active" id="active_field" :true-value="1" :false-value="0" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </Form>
            </div>
            <div class="modal_footer">
                <button :disabled="loader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                <button :disabled="loader" class="btn save_btn" @click="handleSubmitForm()"><i class="fa fa-spin fa-spinner" v-if="loader"></i> {{ loader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Add Custom Field',

        data () {
            return {
                form: {
                    name: '',
                    is_active: 1,
                },
                loader: false
            }
        },

        props: {
            modelValue: Boolean,
        },

        emit: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.form = {
                        name: '',
                        is_active: 1,
                    };
                }
            }
        },

        methods: {
            ...mapActions({
                saveCustomField: 'contactModule/saveCustomField',
                getCustomFields: 'contactModule/getCustomFields',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmitForm () {
                const vm = this;

                const form = vm.$refs['add-custom-field-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.handleSubmit(form);
                    }
                });
            },

            handleSubmit (form) {
                const vm  = this;

                vm.loader = true;
                vm.form.setFieldError = form.setFieldError;

                vm.saveCustomField(vm.form).then((result) => {
                    if (result) {
                        vm.getCustomFields({ active: 1, type: 'all' });
                        vm.closeModal();
                    }

                    vm.loader = false;
                });
            },
        }
    }
</script>
